import {Trans} from '@ui/i18n/trans';
import {FormattedPrice} from '@common/billing/formatted-price';
import {useCheckoutProduct} from '@common/billing/requests/use-checkout-product';
import {m} from 'framer-motion';
import {Skeleton} from '@ui/skeleton/skeleton';
import {Product} from '@common/billing/product';
import {Price} from '@common/billing/price';
import {ProductFeatureList} from '../pricing-table/product-feature-list';
import {opacityAnimation} from '@ui/animation/opacity-animation';
import {FormattedCurrency} from '@ui/i18n/formatted-currency';
import {Button} from '@ui/buttons/button';
import {apiClient} from '@common/http/query-client';
import {useParams} from 'react-router-dom';
import {useState} from 'react';
import {toast} from '@ui/toast/toast';

interface CheckoutProductSummaryProps {
  showBillingLine?: boolean;
}
function formatToToman(amount: number) {
  return `${(amount / 10).toLocaleString()} تومان`;
}
export function CheckoutProductSummary({
  showBillingLine = true,
}: CheckoutProductSummaryProps) {
  const {status, product, price} = useCheckoutProduct();

  const [loading, setLoading] = useState('notrequested');
  const {productId, priceId} = useParams();

function zarrinRequest(payload: any): Promise<Response> {
  toast('!در حال انتقال، از قطع بودن فیلترشکن مطمئن شو', {type: 'loading', duration: 6000}); 
  setLoading('pending');
  const searchParams = new URLSearchParams(window.location.search);
  const renewalType = searchParams.get('type');
  const subscriptionId = searchParams.get('subscription_id');

  return apiClient
    .post('payment/request', {
      price_id: priceId,
      amount: price?.amount!,
      type: renewalType || 'new',
      subscription_id: subscriptionId // اضافه کردن این خط
    })
    .then(response => {
      setLoading('success');
      if (response.status === 200) {
        const {data} = response.data;
        if (data && data.url) {
          window.location.href = data.url;
        }
      }
      return response.data;
    })
    .catch(error => {
      setLoading('error');
      setTimeout(() => {
        setLoading('notrequested');
      }, 3000);
    });
}

  if (status === 'error' || (status !== 'pending' && (!product || !price))) {
    return null;
  }

  return (
    <div>
      <h2 className="mb-30 text-2xl">
        <Trans message="Summary" />
      </h2>
      {status === 'pending' ? (
        <LoadingSkeleton key="loading-skeleton" />
      ) : (
        <ProductSummary
          product={product!}
          price={price!}
          showBillingLine={showBillingLine}
          zarrinRequest={zarrinRequest}
          loading={loading}
        />
      )}
    </div>
  );
}

interface ProductSummaryProps {
  product: Product;
  price: Price;
  showBillingLine: boolean;
  zarrinRequest: any;
  loading: any;
}
function ProductSummary({
  product,
  price,
  showBillingLine,
  zarrinRequest,
  loading,
}: ProductSummaryProps) {
  return (
    <m.div>
      <div className="mb-6 text-xl font-semibold">{product.name}</div>
      {product.description && (
        <div className="text-sm text-muted">{product.description}</div>
      )}
      <FormattedPrice
        priceClassName="font-bold text-4xl"
        periodClassName="text-muted text-xs"
        variant="separateLine"
        price={price}
        className="mt-32"
      />
      <ProductFeatureList product={product} />
      {showBillingLine && (
        <div className="mt-32 flex items-center justify-between gap-24 border-t pt-24 font-medium">
          <div>
            <Trans message="Billed today" />
          </div>
          <div style={{ direction: 'rtl' }}>
            {formatToToman(price.amount)}
          </div>
        </div>
      )}
      <Button
        variant={'flat'}
        color={
          loading === 'pending'
            ? 'paper'
            : loading === 'success'
            ? 'positive'
            : 'primary'
        }
        className="mt-12 w-full"
        size="md"
        elementType={undefined}
        disabled={loading !== 'notrequested'}
        onClick={zarrinRequest}
      >
        {loading === 'pending' ? (
          <Trans message="Processing..." />
        ) : loading === 'success' ? (
          <Trans message="Transferring ..." />
        ) : (
          <Trans message="Go to Payment Page" />
        )}
      </Button>
    </m.div>
  );
}

function LoadingSkeleton() {
  return (
    <m.div {...opacityAnimation} className="max-w-180">
      <Skeleton className="mb-6 text-xl" />
      <Skeleton className="text-sm" />
      <Skeleton className="mt-32 text-4xl" />
    </m.div>
  );
}
